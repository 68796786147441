import React from "react"

import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"

import BiContent from "../../components/bi-content"
import Button from "../../components/button"
import MainBanner from "../../components/main-banner"
import ContactOptions from "../../components/contact-options"

import MeiquerLabsLogo from "../../assets/logos/logo-meiquer-labs.svg"
import PlaceholderImage from "../../assets/placeholders/placeholder-01@2x.png"

const mainHeaderContent = (
  <div>
    <p
      style={{
        marginBottom: "1rem",
        color: "black",
        fontWeight: 500,
        fontSize: "1.3em",
      }}
    >
      Inspira. Crea. Transforma.
    </p>
    <p>
      Diseñamos experiencias de{" "}
      <span className="c-turquoise w-bold">juego</span> creativo colaborando con{" "}
      <span className="w-bold">organizaciones, empresas y gobierno</span> para
      transformar comunidades en México.
    </p>
  </div>
)

const mainHeaderTitle = (
  <>
    <img
      style={{ width: "7em" }}
      src={MeiquerLabsLogo}
      alt="Meiquer Labs Logo"
    />
    <h1 className="w-normal">
      <span className="w-bold">Colaboraciones de</span>{" "}
      <span className="c-turquoise w-bold">Juego</span>
    </h1>
  </>
)

const infoBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Colaborando</span> para{" "}
      <span className="c-turquoise w-bold">jugar</span> con
      <span className="s-italic">propósito</span>{" "}
    </h2>
    <p>
      El juego es el motor de Estación Meiquer y una herramienta universal para
      crear experiencias y desarrollar habilidades.
    </p>
    <p>
      Conoce las historias de cómo hemos colaborado con Organizaciones,
      Empresas, Gobierno y Escuelas en programas, talleres y cursos diseñados a
      la medida.
    </p>
    <div className="columns">
      <div className="column is-two-thirds pl-0">
        <Button linkTo="/servicios-profesionales/meiquer-labs-historias">
          <span>Descubrir</span>
        </Button>
      </div>
    </div>
  </>
)

const ServiciosProfesionalesPage = props => {
  const data = useStaticQuery(graphql`
    query ServiciosProfesionalesQuery {
      file(relativePath: { eq: "meiquer-fonnor-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Organizaciones y Empresas" />
      <BiContent
        image={PlaceholderImage}
        content={mainHeaderContent}
        title={mainHeaderTitle}
      />
      <MainBanner
        image={data.file.childImageSharp.fluid}
        content={infoBannerContent}
        fromBottom={false}
      />
      <ContactOptions />
    </Layout>
  )
}

export default ServiciosProfesionalesPage
